import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { store } from "../../stores";
import UnknowPage from "../unknowPage";
import { Route, Switch } from "react-router";
import ApiCaller from "../../Api/Connection/ApiCaller";
import { useHistory, useLocation } from "react-router-dom";
function RenderAuth(props) {
  const [lstFunction, setListFunction] = useState(
    props.global.lstFunction || []
  );
  const [check, setCheck] = useState(null);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [path, setPath] = useState(props.path);
  const location = useLocation();
  var Comp = props.component;
  let token = props.global.token || "";
  useEffect(() => {
    if (token === "" || token === undefined) {
      history.push("/login");  
    }
  }, [token]);
  useEffect(() => {
    if (path && path.toLowerCase() == location.pathname.toLowerCase()) {
      ApiCaller.get("Auth/me", null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {})
        .catch((ex) => {
          if (ex.toString().includes("Request failed with status code 401")) {
            localStorage.setItem("token", "");
            localStorage.setItem("name", "");
            localStorage.setItem("groupid", "");
            localStorage.setItem("title", "");
            localStorage.setItem("userID", "");
            localStorage.removeItem("hideZoomGuideline");
            localStorage.setItem("lstFunction", JSON.stringify([]));
            store.dispatch({
              type: "USER_SET_DATA",
              data: { token: "", name: "", lstFunction: [] },
            });
          }
        });
    }
  }, [location.pathname]);

  function findUrl(array, url) {
    for (const item of array) {
      if (item.to && item.to.toLowerCase() === url) return item;
      if (item.content?.length) {
        const innerResult = findUrl(item.content, url);
        if (innerResult) return innerResult;
      }
    }
  }


  const checkFunction = () => {
    if (token != "" && lstFunction.length > 0) {
      const checked = findUrl(lstFunction, location.pathname.toLowerCase());
      if(checked) {
        setCheck(true);
      } else {
        setCheck(false);
      }
    }
  };

  useMemo(() => {
    checkFunction();
  }, [history]);

  if (lstFunction.length === 0) {
    return (
      <React.Fragment>
        <Route path={path} component={UnknowPage} />
      </React.Fragment>
    );
  } else if (token !== "" && props.path === undefined) {
    return (
      <React.Fragment>
        <Route path={path} component={Comp} />
      </React.Fragment>
    );
  } else if (token !== "" && check && props.path !== undefined) {
    return (
      <React.Fragment>
        <Route path={path} component={Comp} />
      </React.Fragment>
    );
  } else if (check === false) {
    return (
      <React.Fragment>
        <Route path={path} component={UnknowPage} />
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  global: state.global,
});

export default connect(mapStateToProps)(RenderAuth);

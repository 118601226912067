import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import TriggerLogout from "../../Components/TriggerLogout";
import ThemeOptions from "../ThemeOptions";
import KeyboardShortcut from "../../Components/KeyboardShortcut";
import Loader from "react-loaders";
import { ConfigProvider } from "antd";
import vi from "antd/locale/vi_VN";
import en from "antd/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import "dayjs/locale/en";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

dayjs.locale("vi");

const RouterCommon = lazy(() => import("../../OneLIS/Routes/RouterCommon.js"));

const AppMain = (props) => {
  const locale = localStorage.getItem("I18N_LANGUAGE") === "vi" ? vi : en;
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          motion: false, // Optimize performance
          colorTextPlaceholder: '#9e9e9e',
        },
        components: {
          Menu: {
            itemSelectedBg: "#c0c3f7",
            itemSelectedColor: "#545cd8",
            subMenuItemBg: '#FFF',
            itemHeight: 35,
            itemMarginBlock: 0,
            iconMarginInlineEnd: 5,
            dangerItemSelectedBg: '#f87171',
            dangerItemSelectedColor: '#dc2626'
          },
        },
      }}
    >
      <Fragment>
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-rise" />
                </div>
                <h6 className="mt-5">
                  {props.t("Loading data")}
                  <small>{props.t("Please wait a second !")}</small>
                </h6>
              </div>
            </div>
          }
        >
          <Route
            path="/OneLIS"
            render={(_props) => (
              <RouterCommon globalProps={props} {..._props} />
            )}
          />
        </Suspense>
        <Route
          exact
          path="/"
          render={() => <Redirect to={props.urldefault} />}
        />
        <TriggerLogout />
        <KeyboardShortcut />
      </Fragment>
    </ConfigProvider>
  );
};
const mapStateToProps = (state) => ({
  global: state.global,
});
export default connect(mapStateToProps)(
  withTranslation(["configure"])(AppMain)
);

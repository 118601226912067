// Sử dụng tính năng auto logout khi hết thời gian mặc định mà người dùng chưa thao tác trên trang web
export const IS_USE_AUTO_LOGOUT_TIMER = true;

// Thời gian ngừng tương tác để logout tự động
export const TIMEOUT_LOGOUT = (window.publicConfig.TIMEOUT_LOGOUT || 15) * 60 * 1000// 15 phút

// Thời gian kiểm tra định kì điều kiện logout
export const TIMEOUT_CHECKING = 1000;  // 1 giây

// Các trang không áp dụng tính năng tự logout
// Giá trị ở mảng này nằm trên đường dẫn đến trang. Tính từ dấu "/" cuối cùng trên url
export const IGNORE_PAGES = []
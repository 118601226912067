import axios from "axios";
import React from "react";
import * as configApi from "../../../config/config_System";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { store } from "../../../stores";

//Config common

const apiDefault = axios.create({
  baseURL: configApi.BASEURL_API,
  timeout: configApi.TIMEOUT,
});

const methodConst = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
  options: "options",
};
class ApiCaller {
  static state = ApiCaller.getDefaultState();

  static getDefaultState() {
    return {
      ...ToastContainer.defaultProps,
      transition: "bounce",
      type: "error",
      disableAutoClose: false,
    };
  }
  static async get(url, params = null, customConfig = null, ShowToast = false) {
    return ApiCaller.callApi(
      url,
      methodConst.get,
      null,
      params,
      customConfig,
      ShowToast
    );
  }
  static async post(url, data, customConfig = null, ShowToast = false) {
    return await ApiCaller.callApi(
      url,
      methodConst.post,
      data,
      null,
      customConfig,
      ShowToast
    );
  }
  static async put(url, data, customConfig = null, ShowToast = false) {
    return await ApiCaller.callApi(
      url,
      methodConst.put,
      data,
      null,
      customConfig,
      ShowToast
    );
  }
  static async delete(
    url,
    data = null,
    customConfig = null,
    ShowToast = false
  ) {
    return await ApiCaller.callApi(
      url,
      methodConst.delete,
      data,
      null,
      customConfig,
      ShowToast
    );
  }
  static async options(url) {
    return await ApiCaller.callApi(url, methodConst.options);
  }

  static async callApi(
    url,
    method,
    data = null,
    params = null,
    customConfig = null,
    ShowToast = false
  ) {
    //handle config common
    let config = {};
    if (customConfig != null && customConfig !== undefined) {
      config = customConfig;
    }
    config.url = url;
    config.method = method;
    // if (ShowToast) {
    //   toast.info("Đang tải dữ liệu...", { autoClose: 300000 });
    // }
    if (params != null) {
      config.params = params;
    }
    if (
      data != null &&
      (method === methodConst.post ||
        method === methodConst.put ||
        method === methodConst.delete)
    ) {
      config.data = data;
    }
    //Gắn token khi gọi api
    config.headers = { Authorization: "Bearer " + global.localStorage.token };
    //Call api
    return await apiDefault
      .request(config)
      .then((response) => {
        // if (!response.data.isSuccess) {
        //     ApiCaller.handleApiUnsuccessful(response.data.err);
        // }
        return response;
      })
      .catch((error) => {
        if (error.response?.status == 401) {
          localStorage.setItem("token", "");
          localStorage.setItem("name", "");
          localStorage.setItem("groupid", "");
          localStorage.setItem("title", "");
          localStorage.setItem("userID", "");
          localStorage.setItem("siteCode", "");
          localStorage.setItem("lstFunction", JSON.stringify([]));
          localStorage.removeItem("hideZoomGuideline");
          localStorage.removeItem("pinShortcutHelper");
          localStorage.removeItem("isReceiveAllSample");
          localStorage.removeItem("permission");
          localStorage.removeItem("themeConfig");
          store.dispatch({
            type: "USER_SET_DATA",
            data: { token: "", name: "", lstFunction: [] },
          });
          window.location.href = "/login";
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setTimeout(() => {
          toast.dismiss();
        }, [3000]);
      });
  }
  //----------------------------------------Custom
  static async getCustom(
    urlBase,
    url,
    params = null,
    customConfig = null,
    ShowToast = false
  ) {
    return ApiCaller.callApiCustom(
      urlBase,
      url,
      methodConst.get,
      null,
      params,
      customConfig,
      ShowToast
    );
  }

  static async postCustom(
    urlBase,
    url,
    data,
    customConfig = null,
    ShowToast = false
  ) {
    return await ApiCaller.callApiCustom(
      urlBase,
      url,
      methodConst.post,
      data,
      null,
      customConfig,
      ShowToast
    );
  }

  static async callApiCustom(
    urlBase,
    url,
    method,
    data = null,
    params = null,
    customConfig = null,
    ShowToast = false
  ) {
    const apiDefaultCustom = axios.create({
      baseURL: urlBase,
      timeout: configApi.TIMEOUT,
    });

    //handle config common
    let config = {};
    if (customConfig != null && customConfig !== undefined) {
      config = customConfig;
    }
    config.url = url;
    config.method = method;
    // if (ShowToast) {
    //   toast.info("Đang tải dữ liệu...", { autoClose: 300000 });
    // }
    if (params != null) {
      config.params = params;
    }
    if (
      data != null &&
      (method === methodConst.post ||
        method === methodConst.put ||
        method === methodConst.option ||
        method === methodConst.delete)
    ) {
      config.data = data;
    }
    ////Gắn token khi gọi api
    //config.headers = { Authorization: "Bearer " + global.localStorage.token };
    //Call api
    return await apiDefaultCustom
      .request(config)
      .then((response) => {
        // if (!response.data.isSuccess) {
        //     ApiCaller.handleApiUnsuccessful(response.data.err);
        // }
        return response;
      })
      .catch((error) => {
        if (error.response?.status == 401) {
          localStorage.setItem("token", "");
          localStorage.setItem("name", "");
          localStorage.setItem("groupid", "");
          localStorage.setItem("title", "");
          localStorage.setItem("userID", "");
          localStorage.setItem("siteCode", "");
          localStorage.setItem("lstFunction", JSON.stringify([]));
          localStorage.removeItem("hideZoomGuideline");
          localStorage.removeItem("pinShortcutHelper");
          localStorage.removeItem("isReceiveAllSample");
          localStorage.removeItem("permission");
          localStorage.removeItem("themeConfig");
          store.dispatch({
            type: "USER_SET_DATA",
            data: { token: "", name: "", lstFunction: [] },
          });
          window.location.href = "/login";
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setTimeout(() => {
          toast.dismiss();
        }, [3000]);
      });
  }
  //function ultis
  static async handleApiUnsuccessful(error) {
    console.error("apiDefault error", error);
    try {
      const errStr =
        "Mã lỗi: " + error.msgCode + "; Nội dung: " + error.msgString;
      toast[ApiCaller.state.type](errStr, { autoClose: 3000 });
    } catch (error) {}
    // console.error("handleApiUnsuccessful error", error);
  }
  //function ultis
  static async handleErrorApi(error) {
    let errStr = "";
    try {
      if (error.message === "Network Error") {
        errStr = "Không thể kết nối tới server";
      } else {
        errStr = error.message;
      }
    } catch (error) {
      errStr = JSON.stringify(error);
    }
    toast[ApiCaller.state.type](errStr, { autoClose: 3000 });
    // console.error("handleErrorApi error", error);
  }
}

const mapStateToProps = (state) => ({
  global: state.global,
});
export default connect(mapStateToProps)(ApiCaller);

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Row,
  Col,
} from "reactstrap";
import Switch from "react-switch";
import { Typography } from "antd";
import {
  shortcutConfigGeneral,
  shortcutConfig,
  shortcutConfigFunc,
} from "../../config/shortcut";
import { useTranslation } from "react-i18next";

const Index = ({
  isOpen,
  setIsOpen,
  pinShortcutHelper,
  setPinShortcutHelper,
}) => {
  const { t } = useTranslation(["menu", "configure"]);

  const content = (data, keyboardWidth, prefixTranslation) =>
    Object.keys(data).map((key) => (
      <Card className="px-3 py-2 mt-2">
        <div className="d-flex align-items-center">
          <Typography.Title
            level={5}
            className="mb-0 font-weight-bold"
            style={{ width: keyboardWidth, color: "#545cd8" }}
          >
            {key}
          </Typography.Title>
          <Typography className="mb-0" style={{ fontSize: ".9rem" }}>
            {t(prefixTranslation ? `${prefixTranslation}:${data[key].title}` : data[key].title).split("(")[0]}
          </Typography>
        </div>
      </Card>
    ));

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen(false)}
      style={{ maxWidth: 900, minWidth: 300, width: "80%" }}
    >
      <ModalHeader>{t("configure:KeyboardShortcut")}</ModalHeader>
      <ModalBody style={{ paddingTop: 0 }}>
        <Row>
          <Col md={6} lg={4} sm={12}>
            <Typography.Title level={5} className="mt-3 mb-1">
              {t("configure:General")}
            </Typography.Title>
            {content(shortcutConfigGeneral, "70px", "configure")}
          </Col>
          <Col md={6} lg={4} sm={12}>
            <Typography.Title level={5} className="mt-3 mb-1">
              {t("configure:FastChangePage")}
            </Typography.Title>
            {content(shortcutConfig, "80px")}
          </Col>
          <Col md={6} lg={4} sm={12}>
            <Typography.Title level={5} className="mt-3 mb-1">
              {t("configure:FastAction")}
            </Typography.Title>
            {content(shortcutConfigFunc, "70px", "configure")}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <div className="w-100">
          <Row>
            <Col sm={12} md={9}>
              <div className="d-flex align-items-center">
                <div style={{ maxWidth: 360 }}>
                  <Typography>
                    <b>{t("configure:PinShortcutHelper")}</b>
                  </Typography>
                  <Typography style={{ fontSize: ".8rem" }}>
                    <i>{t("configure:PinShortcutHelperDesc")}</i>
                  </Typography>
                </div>
                <div>
                  <Switch
                    height={30}
                    width={50}
                    onColor="#00ff15"
                    offColor="#c7c4c4"
                    uncheckedIcon={null}
                    checkedIcon={null}
                    onChange={setPinShortcutHelper}
                    checked={pinShortcutHelper}
                  />
                </div>
              </div>
            </Col>

            <Col sm={12} md={3} className="mt-4">
              <div className="d-flex justify-content-end align-items-end">
                <Button color="danger" onClick={() => setIsOpen(false)}>
                  {t("configure:Close")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Index;

export let BASEURL = window.publicConfig.BASEURL;
export let BASEURL_API = window.publicConfig.BASEURL_API;
export let REALTIME_API = window.publicConfig.REALTIME_API;
export let TIMEOUT = window.publicConfig.TIMEOUT;

export let SOFTWARE_NAME = window.publicConfig.SOFTWARE_NAME;
export let SOFTWARE_NAME_FULL = window.publicConfig.SOFTWARE_NAME_FULL;

export let COMPANY_NAME = window.publicConfig.COMPANY_NAME;
export let COMPANY_NAME_COLOR = window.publicConfig.COMPANY_NAME_COLOR;

export let URL_LOGIN_LOGO = window.publicConfig.URL_LOGIN_LOGO;
export let URL_MAIN_LOGO = window.publicConfig.URL_MAIN_LOGO;
export let URL_BACKGROUND_LOGIN = window.publicConfig.URL_BACKGROUND_LOGIN;

export let LOGIN_LOGO_HEIGHT = window.publicConfig.LOGIN_LOGO_HEIGHT;
export let LOGIN_LOGO_WIDTH = window.publicConfig.LOGIN_LOGO_WIDTH;

export let MAIN_LOGO_HEIGHT = window.publicConfig.MAIN_LOGO_HEIGHT;
export let MAIN_LOGO_WIDTH = window.publicConfig.MAIN_LOGO_WIDTH;
export let COPYRIGHT = window.publicConfig.COPYRIGHT;
export let RECAPTCHA_SITE_KEY_V3 = window.publicConfig.RECAPTCHA_SITE_KEY_V3;
export let THEME_CONFIG = window.publicConfig.THEME_CONFIG;

export let LOGIN_SLIDER = window.publicConfig.LOGIN_SLIDER;
export let INSTRUCTION_MANUAL_LINK = window.publicConfig.INSTRUCTION_MANUAL_LINK;






export const unloadedState = {
  token: localStorage.getItem("token") || undefined,
  name: localStorage.getItem("name") || undefined,
  groupid: localStorage.getItem("groupid") || undefined,

  userID: localStorage.getItem("userID") || undefined,
  // siteCode:l
  title: localStorage.getItem("title") || undefined,

  siteCode: localStorage.getItem("siteCode") || undefined,
  tabs: [],
  activeTab: 0,
  patient: {},
  print: false,
  file: null,
  resetBarCode: false,
  lstFunction: localStorage.getItem("lstFunction")
    ? JSON.parse(localStorage.getItem("lstFunction"))
    : [],
};

export const Reducers = (state, incomingAction) => {
  if (state === undefined) {
    return unloadedState;
  }
  const action = incomingAction;
  switch (action.type) {
    case "USER_SET_DATA":
      return action.data;
    case "TAB_CLEAR":
      return {
        ...state,
        tabs: [],
        activeTab: 0,
      };
    case "SET_TAB":
      return {
        ...state,
        tabs: action.tabs,
        activeTab: action.activeTab,
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case "MODAL_OPEN_PRINT_MODAL":
      return {
        ...state,
        print: true,
        file: action.file,
      };
    case "SET_DATA_BARCODE":
      return {
        ...state,
        data: action.data,
      };
    case "MODAL_CLOSE_PRINT_MODAL":
      return {
        ...state,
        print: false,
        file: null,
      };
    case "RELOAD_TAB":
      return {
        ...state,
        print: false,
        file: null,
      };
    case "RESET_BARCODE":
      return {
        ...state,
        resetBarCode: action.data,
      };
    default:
  }

  return state;
};

export const actions = {};

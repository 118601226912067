import React, { useCallback, useEffect, useState } from "react";
import { FloatButton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import { shortcutConfig } from "../../config/shortcut";
import ThemeOptions from "../../Layout/ThemeOptions";

const Index = () => {
  const { t } = useTranslation(["configure"]);
  const [showModal, setShowModal] = useState(false);
  const [pinShortcutHelper, setPinShortcutHelper] = useState(() => {
    const localData = localStorage.getItem("pinShortcutHelper");
    if (!localData) {
      localStorage.setItem("pinShortcutHelper", "0");
      return false;
    }

    return localData === "1";
  });
  const [pinThemeConfig, setPinThemeConfig] = useState(() => {
    const localData = localStorage.getItem("pinThemeConfig");
    if (!localData) {
      localStorage.setItem("pinThemeConfig", "0");
      return false;
    }

    return localData === "1";
  });
  const history = useHistory();

  // Định nghĩa các sự kiện khi nhấn bàn phím
  const handleKeyPress = useCallback(
    (event) => {
      if (shortcutConfig[event.key]) {
        history.push(shortcutConfig[event.key].url);
      }

      if (event.key === "F1") {
        // Ngăn việc mở HelpCenter của Chrome
        event.preventDefault();
        setShowModal((prev) => !prev);
      }

      // Nhấn Alt + P
      if ((event.metaKey || event.altKey) && event.key === "p") {
        const newValue = !pinShortcutHelper;
        setPinShortcutHelper(newValue);
        localStorage.setItem("pinShortcutHelper", newValue ? "1" : "0");
      }

      // Nhấn Alt + T
      if ((event.metaKey || event.altKey) && event.key === "t") {
        const newValue = !pinThemeConfig;
        setPinThemeConfig(newValue);
        localStorage.setItem("pinThemeConfig", newValue ? "1" : "0");
      }

      // Nhấn ctrl + alt
      // if ((event.metaKey || event.ctrlKey) && event.key === "Alt") {
      //   setShowModal(prev => !prev);
      // }
    },
    [history, pinShortcutHelper, pinThemeConfig]
  );

  useEffect(() => {
    // Đăng ký sự kiện khi khởi tạo
    document.addEventListener("keydown", handleKeyPress);

    // Hủy sự kiện khi component bị gỡ khỏi DOM
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress, pinShortcutHelper]);

  return (
    <div>
      <Modal
        isOpen={showModal}
        setIsOpen={setShowModal}
        pinShortcutHelper={pinShortcutHelper}
        setPinShortcutHelper={(value) => {
          setPinShortcutHelper(value);
          localStorage.setItem("pinShortcutHelper", value ? "1" : "0");
        }}
      />
      {pinShortcutHelper && (
        <FloatButton
          style={{ bottom: 5, right: pinThemeConfig ? 50 : 5, paddingRight: 2 }}
          type="primary"
          onClick={() => setShowModal((prev) => !prev)}
          tooltip={<div>{t("KeyboardShortcut")}</div>}
          icon={<FontAwesomeIcon icon={faKeyboard} />}
        />
      )}
      {pinThemeConfig && <ThemeOptions />}
    </div>
  );
};

export default Index;

const shortcutConfigGeneral = {
  F1: {
    title: "ShowKeyboardShortcut",
  },
  "Alt + P": {
    title: "PinShortcutHelper",
  },
  "Alt + T": {
    title: "PinThemeConfig"
  }
};

const shortcutConfig = {
  F2: {
    title: "Sample List",
    url: "/OneLIS/ReceptionPatient",
  },
  F4: {
    title: "Test Results",
    url: "/OneLIS/ValidResult",
  },
  F8: {
    title: "Sample Delivery",
    url: "/OneLIS/sampledelivery",
  },
  F9: {
    title: "Machine Results",
    url: "/OneLIS/InstrumentResult",
  },
};

const shortcutConfigFunc = {
  "Alt + V": {
    title: "ValidTestResult",
  },
  "Alt + S": {
    title: "SignTestResult",
  },
};

export { shortcutConfig, shortcutConfigFunc, shortcutConfigGeneral };

import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { store } from "../../stores";
import {
  TIMEOUT_CHECKING,
  TIMEOUT_LOGOUT,
  IGNORE_PAGES,
  IS_USE_AUTO_LOGOUT_TIMER,
} from "../../config/triggerLogout";
import { onLogOut } from "../../Api/AppBackend/Auth";
import { message } from "antd";

const Index = () => {
  const location = useLocation();
  const timeoutId = useRef(null);

  const logout = () => {
    onLogOut()
      .then((res) => {
        if (res.data.isSuccess) {
          localStorage.setItem("token", "");
          localStorage.setItem("name", "");
          localStorage.setItem("groupid", "");
          localStorage.setItem("title", "");
          localStorage.setItem("userID", "");
          localStorage.setItem("siteCode", "");
          localStorage.setItem("lstFunction", JSON.stringify([]));
          localStorage.removeItem("hideZoomGuideline");
          localStorage.removeItem("pinShortcutHelper");
          localStorage.removeItem("isReceiveAllSample");
          localStorage.removeItem("permission");
          localStorage.removeItem('themeConfig');
          store.dispatch({
            type: "USER_SET_DATA",
            data: { token: "", name: "", lstFunction: [] },
          });
          window.location.href = "/login";
        } else {
          message.error("Log out failed");
        }
      })
      .catch((err) => {
        message.error("Log out failed");
      });
  };

  // Kiểm tra trang hiện tại được phép dùng tính năng auto logout hay không???
  const isPagePreventAutoLogout = () => {
    const pthName = location.pathname.toLowerCase();
    const splitted = pthName.split("/");
    if (!splitted.length) return false;
    const pageName = splitted.slice(-1)[0];
    const ignorePageLC = IGNORE_PAGES.map((el) => el.toLowerCase());
    return ignorePageLC.includes(pageName);
  };

  const updateInteractive = () => {
    localStorage.setItem("interactiveAt", new Date());
  };

  // Sau mỗi khoảng thời gian TIMEOUT thì hàm này sẽ được gọi để kiểm tra
  // Kiểm tra thời gian tương tác gần nhất đã lưu trong localStorage và thời gian hiện tại
  // Nếu thời gian hiện tại - thời gian tương tác gần nhất > TIMEOUT => tiến hành LOGOUT
  const logoutChecker = () => {
    const interactiveLocal = localStorage.getItem("interactiveAt");
    const latestInteractive = new Date(interactiveLocal);

    const diff = new Date().getTime() - latestInteractive.getTime();
    const timeoutLogout = TIMEOUT_LOGOUT - TIMEOUT_CHECKING;
    if (diff >= timeoutLogout) {
      logout();
    }
  };

  useEffect(() => {
    if (isPagePreventAutoLogout() || !IS_USE_AUTO_LOGOUT_TIMER) return;

    const bodies = document.getElementsByTagName("BODY");

    if (!bodies || !bodies.length) return;

    const bodyElement = bodies[0];

    updateInteractive();
    // Chạy lần đầu tiên
    timeoutId.current = setInterval(() => {
      logoutChecker();
    }, TIMEOUT_CHECKING);
    // Khi kích hoạt event click.
    // * Gán lại thời gian tương tác gần nhất
    // * Đặt lại bộ đếm thời gian để kiểm tra định kì sau mỗi khoảng TIMEOUT (interval)
    bodyElement.addEventListener("click", function () {
      updateInteractive();

      // Xóa bộ đếm trước đó
      if (timeoutId.current) {
        clearInterval(timeoutId.current);
        timeoutId.current = null;
      }

      // Tạo bộ đếm mới
      timeoutId.current = setInterval(() => {
        logoutChecker();
      }, TIMEOUT_CHECKING);
    });

    // Component bị gỡ khỏi DOM. Xóa bộ đếm thời gian. Lúc này user thực hiện chuyển trang.
    return () => {
      if (timeoutId.current) {
        clearInterval(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, [location]);

  return <></>;
};

export default React.memo(Index);

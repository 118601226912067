import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import AppMain from "../../Layout/AppMain";
import { isThisSecond } from "date-fns";
import ApiCaller from "../../Api/Connection/ApiCaller";
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
      urldefault: "",
    };
  }
  getFunctionDefault = () => {
    let urldefault = this.state.urldefault;
    let lstFunction = this.props.global.lstFunction;
    for (let i = 0; i < lstFunction.length; i++) {
      if (
        lstFunction[i].to != null &&
        lstFunction[i].to != undefined &&
        lstFunction[i].to.length > 0
      ) {
        urldefault = lstFunction[i].to;
        i = lstFunction.length;
      } else {
        if (
          lstFunction[i].content != null &&
          lstFunction[i].content != undefined &&
          lstFunction[i].content.length > 0
        ) {
          for (let j = 0; j < lstFunction[i].content.length; j++) {
            if (
              lstFunction[i].content[j].to != null &&
              lstFunction[i].content[j].to != undefined &&
              lstFunction[i].content[j].to.length > 0
            ) {
              urldefault = lstFunction[i].content[j].to;
              j = lstFunction[i].content.length;
            }
          }
          if (urldefault.length > 0) {
            i = lstFunction.length;
          }
        }
      }
    }
    this.setState({ urldefault });
  };
  componentDidMount() {
    this.getFunctionDefault();
  }
  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width, height }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}
            >
              <AppMain urldefault={this.state.urldefault} screen={{width, height}} />
            </div>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  global: state.global,
});

export default withRouter(connect(mapStateToProp)(Main));

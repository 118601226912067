import React, { Fragment, useEffect, useState, useCallback } from "react";

import Slider from "react-slick";
import SimpleReactValidator from "simple-react-validator";
import bg1 from "../../assets/utils/images/originals/city.jpg";
import bg2 from "../../assets/utils/images/originals/citydark.jpg";
import bg3 from "../../assets/utils/images/originals/citynights.jpg";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Select from "react-select";
import history from "../../stores/history";
import { store } from "../../stores";
import ApiCaller from "../../Api/Connection/ApiCaller";
import * as configApi from "../../config/config_System";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { withTranslation } from "react-i18next";
import languages from "../../config/languages";
import { get, map } from "lodash";
import i18n from "../../i18n";
import { message } from "antd";

message.config({
  maxCount: 2,
});
const LoginValidator = new SimpleReactValidator();
async function handleSubmit(
  username,
  password,
  siteCode,
  props,
  executeRecaptcha
) {
  if (!username) {
    message.error(props.t("Please enter your username"));
  } else if (!password) {
    message.error(props.t("Please enter your password"));
  } else {
    if (LoginValidator.allValid()) {
      let recaptchaToken;

      if (configApi.RECAPTCHA_SITE_KEY_V3) {
        recaptchaToken = await executeRecaptcha();
      }

      await ApiCaller.post("/Auth/SignIn", {
        UserName: username,
        Password: password,
        SiteCode: siteCode,
        ...(recaptchaToken ? { RecaptchaToken: recaptchaToken } : {}),
      })
        .then(async (res) => {
          if (res.data.isSuccess) {
            let token = "";
            let name = "";
            let userID = "";
            let groupid = "";
            let title = "";
            let siteCode = "";
            if (res.data.token) {
              token = res.data.token;
              name = res.data.name || "";
              userID = res.data.userID || "";
              title = res.data.title || "";
              siteCode = res.data.siteCode || "";
            }
            localStorage.setItem("token", token);
            localStorage.setItem("name", name);
            localStorage.setItem("userID", userID);
            localStorage.setItem("groupid", groupid);
            localStorage.setItem("title", title);
            localStorage.setItem("siteCode", siteCode);
            await ApiCaller.get("/ListCommon/GetListMenuForUser").then(
              (response) => {
                localStorage.setItem(
                  "lstFunction",
                  JSON.stringify(response.data.data)
                );
                store.dispatch({
                  type: "USER_SET_DATA",
                  data: {
                    token: res.data.token,
                    name: name,
                    userID: userID,
                    siteCode: siteCode,
                    lstFunction: response.data.data,
                  },
                });
              }
            );
            await ApiCaller.get("/ListCommon/GetListFunctionForUser").then(
              (response) => {
                if (response.data.isSuccess) {
                  const pms = response.data.data.reduce(
                    (a, v) => ({ ...a, [v.functionID]: { ...v } }),
                    {}
                  );
                  localStorage.setItem("permission", JSON.stringify(pms));
                } else {
                  // error
                  localStorage.setItem("permission", JSON.stringify({}));
                }
              }
            );

            const from = "";
            if (props.location.state) {
              from = props.location.state.from;
            }

            if (from !== "" && from !== undefined) {
              history.push(from);
            } else {
              history.push("/");
            }
          } else {
            message.error(props.t(res.data.err.msgString));
          }
        })
        .catch((err) => {
          message.error(err.response.data.err.msgString);
        });
    } else {
      LoginValidator.showMessages();
    }
  }
}
const useForceUpdate = () => {
  const [count, setCount] = useState(0);
  const increment = () => setCount((prevCount) => prevCount + 1);
  return [increment, count];
};

function checkprops(props) {
  if (
    props === undefined ||
    props.global === undefined ||
    props.global.token === undefined
  )
    return;
  if (props && props.global.token !== "") {
    if (props.location && props.location.state) {
      const { from } = props.location.state;
      if (from !== "" && from !== undefined) {
        history.push(from);
      } else {
        history.push("/");
      }
    } else {
      history.push("/");
    }
  }
}

const LoginForm = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isShowLoginSlider = configApi.LOGIN_SLIDER;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [forceUpdate] = useForceUpdate();
  const [showPass, setShowPass] = useState(false);
  const [selectedLang, setSelectedLang] = useState("");
  const [menu, setMenu] = useState(false);
  const [siteCode, setSiteCode] = useState("");
  const [listSiteInfo, setListSiteInfo] = useState([]);
  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
  }, []);
  const getListSite = () => {
    ApiCaller.get("/SiteInfo/GetListSiteActive").then((res) => {
      if (res.data.isSuccess && res.data.data.length > 0) {
        for (let i = 0; i < res.data.data.length; i++) {
          res.data.data[i].selected = true;
          res.data.data[i].value = res.data.data[i].siteCode;
          res.data.data[i].label = res.data.data[i].siteName;
        }
        setListSiteInfo(res.data.data);
        setSiteCode(res.data.data[0].siteCode);
      } else {
        setListSiteInfo([]);
      }
    });
  };
  const changeLanguageAction = (lang) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
    window.location.reload();
  };

  const onClickHandler = (e) => {
    forceUpdate();
  };
  useEffect(() => {
    checkprops(props);
    getListSite();
  }, []);
  const toggle = () => {
    setMenu(!menu);
  };

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          {isShowLoginSlider ? (
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage:
                          "url(" + configApi.URL_BACKGROUND_LOGIN + ")",
                      }}
                    />
                    <div className="slider-content">
                      <h3>GIẢI PHÁP QUẢN LÝ TOÀN DIỆN CHO PHÒNG XÉT NGHIỆM</h3>
                      <p>CHÍNH XÁC – NHANH – HIỆU QUẢ – KINH TẾ</p>
                    </div>
                  </div>
                  {/* <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div
                    className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg3 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>Labconnect DMS</h3>
                    <p>
                      Phẩn mềm quản lý khoa hóa sinh – huyết học Labconnect Phần
                      mềm được thiết kế để hỗ trợ phòng xét nghiệm trong quản lý
                      bệnh nhân và kết quả. Với việc tập trung và chia sẻ dữ
                      liệu , các thông tin được kế thừa và sử dụng lại giúp tăng
                      tính chính xác và hiệu quả hoạt động
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div
                    className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg2 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>Labconnect Web</h3>
                    <p>
                      Phần mềm quản lý phòng khám Labconnect Web Tạo chỉ định
                      xét nghiệm và trả kết quả cho bệnh nhân thông qua trình
                      duyệt Web (sử dụng các thiết bị SmartPhone, Ipad, máy tính
                      bảng…) một cách nhanh chóng, hiệu quả và chính xác.
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div
                    className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg1 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>Labconnect BloodBank</h3>
                    <p>
                      Phần mềm quản lý ngân hàng máu Labconnect BloodBank Phần
                      mềm hỗ trợ người dùng quản lý thông tin ngân hàng máu, từ
                      thông tin người hiên máu, quá trình sản xuất túi máu cho
                      đến khi túi máu thành phẩm được xuất kho sử dụng.
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div
                    className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg2 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>Labconnect BloodStorage</h3>
                    <p>
                      Phần mềm quản lý tủ máu Labconnect BloodStorage Quản lý
                      chế phẩm máu và việc cấp phát máu tại bệnh viên
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div
                    className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg3 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>Labconnect Inventory</h3>
                    <p>
                      Phần mềm quản lý vật tư hóa chất Labconnect Inventory Quản
                      lý hoạt động nhập xuất tồn, tiêu hao, dự trù vật tư hóa
                      chất cho phòng xét nghiệm.
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div
                    className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg1 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>Labconnect iQC</h3>
                    <p>
                      Phần mềm quản lý kết quả nội kiểm chuẩn Labconnect.iQC
                      Giúp phòng xét nghiệm quản lý kết quả nội kiểm chính xác,
                      nhanh chóng, hiệu quả.
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div
                    className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg2 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>Labconnect Microbiology</h3>
                    <p>
                      Labconnect Microbiology (có kết nối WHOnet) Quản lý hoạt
                      động của khoa Vi sinh, quá trình nuôi cấy, định danh và
                      kháng sinh đồ. Kết nối các máy nuôi cấy, định danh và
                      kháng sinh đồ. Cho phép thống kê kết quả, báo cáo theo các
                      mẫu đặc trưng.
                    </p>
                  </div>
                </div> */}
                </Slider>
              </div>
            </Col>
          ) : null}
          <Col
            lg={isShowLoginSlider ? "8" : "12"}
            md="12"
            className="h-100 bg-white d-flex justify-content-center align-items-center position-relative"
          >
            <Col lg="9" md="10" sm="12" className="app-login-box">
              <div
                style={{
                  height: configApi.LOGIN_LOGO_HEIGHT,
                  width: configApi.LOGIN_LOGO_WIDTH,
                  backgroundImage: "url(" + configApi.URL_LOGIN_LOGO + ")",
                  backgroundSize: "cover",
                }}
              />

              <h4 className="mb-0 mt-2">
                <div
                  style={{
                    fontSize: "larger",
                    color: configApi.COMPANY_NAME_COLOR,
                    opacity: 1,
                    marginBottom: 8,
                  }}
                >
                  <b>{configApi.COMPANY_NAME}</b>
                </div>
                <div>{props.t(`${configApi.SOFTWARE_NAME_FULL}`)}</div>
                <div>
                  <b>
                    {/* <span
                      style={{ color: "red", fontSize: "larger", opacity: 1 }}
                    >
                      LAB
                    </span>
                    <span style={{ fontSize: "larger", opacity: 1 }}>
                      CONNECT
                    </span> */}
                    <span
                      style={{
                        color: "rgb(192 24 30)",
                        fontSize: "larger",
                        opacity: 1,
                      }}
                    >
                      {" "}
                      {configApi.SOFTWARE_NAME}
                    </span>
                  </b>
                </div>
                <div style={{ display: "flex" }}>
                  <span> {props.t("Please login your account")}</span>
                </div>
              </h4>
              <Dropdown
                isOpen={menu}
                toggle={toggle}
                className="d-inline-block"
              >
                <DropdownToggle
                  className="btn header-item "
                  tag="button"
                  style={{ paddingLeft: 0 }}
                >
                  <img
                    src={get(languages, `${selectedLang}.flag`)}
                    alt="Skote"
                    height="16"
                    className="me-1"
                  />
                </DropdownToggle>
                <DropdownMenu className="language-switch dropdown-menu-end">
                  {map(Object.keys(languages), (key) => (
                    <DropdownItem
                      key={key}
                      onClick={() => changeLanguageAction(key)}
                      className={`notify-item ${
                        selectedLang === key ? "active" : "none"
                      }`}
                    >
                      <img
                        src={get(languages, `${key}.flag`)}
                        alt="Skote"
                        className="me-1"
                        height="12"
                      />
                      <span
                        className="align-middle"
                        style={{ paddingLeft: 10, paddingBottom: 4 }}
                      >
                        {get(languages, `${key}.label`)}
                      </span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <Row className="divider" />

              <div>
                <Form>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="Username"> {props.t("Username")}</Label>
                        <Input
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          type="Username"
                          name="Username"
                          id="Username"
                          placeholder={props.t("Enter your account here")}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              handleSubmit(
                                username,
                                password,
                                siteCode,
                                props,
                                executeRecaptcha
                              );
                              onClickHandler();
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="examplePassword">
                          {props.t("Password")}
                        </Label>
                        <InputGroup>
                          <InputGroupAddon
                            addonType="prepend"
                            style={{ cursor: "pointer", width: 46 }}
                            onClick={() => {
                              setShowPass(!showPass);
                            }}
                          >
                            <InputGroupText style={{ width: 46 }}>
                              <FontAwesomeIcon
                                icon={showPass ? faEye : faEyeSlash}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            type={showPass ? "text" : "password"}
                            name="password"
                            id="examplePassword"
                            placeholder={props.t("Enter your password here")}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                handleSubmit(
                                  username,
                                  password,
                                  siteCode,
                                  props,
                                  executeRecaptcha
                                );
                                onClickHandler();
                              }
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="siteCode">{props.t("Site")}</Label>
                        <Select
                          id="SiteCode"
                          name="SiteCode"
                          closeMenuOnSelect={true}
                          options={listSiteInfo}
                          value={listSiteInfo.find(
                            ({ value }) => value == siteCode
                          )}
                          onChange={(e) => {
                            setSiteCode(e.siteCode);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <FormGroup check>
                    <Input type="checkbox" name="check" id="exampleCheck" />
                    <Label for="exampleCheck" check>
                      {props.t("Remember password")}
                    </Label>
                  </FormGroup> */}
                  <Row className="divider" />
                  <div className="d-flex justify-content-between align-items-center">
                    {configApi.INSTRUCTION_MANUAL_LINK && (
                      <a href={configApi.INSTRUCTION_MANUAL_LINK} rel="noreferrer" target="_blank">
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            className="instruction-manual-downloader"
                          />
                          <div className="instruction-manual-downloader-text">
                            {props.t("Instruction Manual")}
                          </div>
                        </div>
                      </a>
                    )}
                    <div className="ml-auto">
                      <Button
                        color="primary"
                        size="lg"
                        onClick={(e) => {
                          handleSubmit(
                            username,
                            password,
                            siteCode,
                            props,
                            executeRecaptcha
                          );
                          onClickHandler();
                        }}
                      >
                        {props.t("Login")}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
            <footer
              className="position-absolute"
              style={{ bottom: 0, marginBottom: 5 }}
            >
              {configApi.COPYRIGHT}
            </footer>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

function RenderPageLogin(props) {
  const captchaKey = configApi.RECAPTCHA_SITE_KEY_V3;

  return (
    <>
      {captchaKey ? (
        <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
          <LoginForm {...props} />
        </GoogleReCaptchaProvider>
      ) : (
        <LoginForm {...props} />
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    global: state.global,
  };
}

export default connect(mapStateToProps)(
  withTranslation(["login"])(RenderPageLogin)
);

import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorker from "./serviceWorker";
import { Route, Switch } from "react-router-dom";
import history from "./stores/history";
import { ConnectedRouter } from "connected-react-router";
import 'remixicon/fonts/remixicon.css'
import "./assets/base.scss";
import Main from "./OneLIS/Main";
import configureStore from "./config/configureStore";
import Login from "./Components/login";
import UnknowPage from "./Components/unknowPage";
import Authenticated from "./Components/authenticated";
import { Provider } from "react-redux";
import { store } from "./stores";
//Custom style react table
import "./app.css";
import "./i18n";

// const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = async (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <Authenticated component={Component} />
          <Route path="/error" component={UnknowPage} />
        </Switch>
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./OneLIS/Main", () => {
    const NextApp = require("./OneLIS/Main").default;
    renderApp(NextApp);
  });
}
//serviceWorker.unregister();

import result from "./result.json";
import patient from "./patient.json";
import configure from "./configure.json";
import process from "./process.json";
import login from "./login.json";
import menu from "./menu.json";
import groupExamination from "./groupExamination.json";
import dashboard from "./dashboard.json";
import Statistics from "./Statistics.json";
import sampleDeliveryManagement from "./sampleDeliveryManagement.json";
import customStatistic from "./customStatistic.json";
import iQC from "./iQC.json";
import biobank from "./biobank.json";
const translations = {
  result,
  patient,
  configure,
  process,
  login,
  menu,
  groupExamination,
  dashboard,
  Statistics,
  sampleDeliveryManagement,
  customStatistic,
  iQC,
  biobank,
};
export default translations;
